import { styled } from '@mui/material/styles';
import { FormControl, InputLabel, Badge, Select, MenuItem, Card, Checkbox } from '@mui/material';
import { useColors } from '../../Context/ColorsContext';

import { MediaQuerys } from '../../styles/GlobalStyle';

const { mobile, tablet, desktop } = MediaQuerys;

export const ContainerTitle = styled('div')(({ marginLeft }) => {
    return {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: marginLeft ? marginLeft : null,
    }
})

export const ContainerPageTitle = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem'
    }
});