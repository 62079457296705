import React from 'react'
import { Button, Grid, Switch, Divider } from '@mui/material';
import SyncLockIcon from '@mui/icons-material/SyncLock';

// Components
import { SelectInput } from '../../../../SelectInput';

// Styles
import {
  Container,
  Body,
  ContainerInfo,
  SectionBottomInfo,
  Input,
  Text,
  CustomTextField,
  CustomFormControlLabel,
  CustomIconButton,
  SpanPassword
} from '../styles'


// Utils
import { useTranslation } from 'react-i18next'
import { ColorsContext } from '../../../../../Context/ColorsContext';
import { PasswordGenerator } from '../../../../atoms/passwordGenerator';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../../Context/PermissionsContext';

export const StepOne = ({
  form,
  addRole,
  roleList,
  selectedRole,
  errors,
  loading,
  authTypeRef,
  handleChange,
  handleSetRole,
  handleAuthentication,
  handleValidateEmail,
  validateEmail,
  buttonRef,
  handleGeneratePassword,
  showPasswordGenerator,
  setShowPasswordGenerator,
  handleGenerateClick,
  passwordPolicyData
}) => {
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const { t } = useTranslation();

  return (
    <Container mediumHigh={passwordPolicyData.isActive && passwordPolicyData.passwordSecurity && passwordPolicyData.passwordComments !== ""}>
      <Body>
        <ContainerInfo>
          <Grid container spacing={2}>
              
            <Grid item xs={6}>
              <CustomTextField
                label={t('system.users.table.email')}
                value={form.email}
                onChange={e => handleChange(e, 'email')}
                sx={{ width: '100%' }}
                error={errors.email?.hasError}
                helperText={errors.email?.message}
                disabled={loading || addRole}
              />
            </Grid>
            
            <Grid item xs={3}>
              <CustomFormControlLabel
                control={
                  <Switch checked={authTypeRef}
                    disabled={addRole}
                    onChange={() => {
                      handleAuthentication()
                      setShowPasswordGenerator(false);
                    }} inputProps={{ 'aria-label': 'controlled' }}
                    sx={{
                      '& .MuiSwitch-switchBase.Mui-checked': {
                        color: colors.buttonActionColor
                      },
                      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: colors.buttonActionColor
                      },
                      "& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": {
                        backgroundColor: colors.buttonActionColor
                      },
                    }}
                  />
                }
                label={t('system.users.activeDirectory')} 
              />
            </Grid>

            {ability.can("GetUser") &&
              <Grid item xs={3} display='flex' justifyContent='flex-start' alignItems='center'>
                <Button 
                  variant="contained" 
                  onClick={handleValidateEmail}
                  disabled={addRole}
                  sx={{ 
                    textTransform: 'none', 
                    backgroundColor: colors.buttonActionColor,
                    "&:hover": {
                      background: colors.buttonActionColor,
                      borderColor: colors.buttonActionColor,
                      opacity: 0.8,
                    },
                  }}
                >
                  {t('system.users.validate')}
                </Button>
              </Grid>
            }

            <Grid item xs={12}>
              <Divider orientation="horizontal" />
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                type={"number"}
                label={t('system.users.table.identification')}
                value={form.identification}
                onChange={e => {
                  if (e.target.value >= 0) handleChange(e, 'identification')
                }}
                sx={{ width: '100%' }}
                error={errors.identification?.hasError}
                helperText={errors.identification?.message}
                onKeyDown={(e) => {
                  if (
                    e?.key === "e" ||
                    e?.key === "E" ||
                    e?.key === "-" ||
                    e?.key === "+" ||
                    e?.key === "." ||
                    e?.key === ","
                  ) {
                    e.preventDefault()
                  }
                }}
                disabled={loading || validateEmail || addRole}
              />
            </Grid>

            {ability.can("RoleList") &&
              <Grid item xs={6}>
                <SelectInput
                  label={t('system.users.table.role')}
                  onChange={(value) => {
                    handleSetRole(value);
                  }}
                  value={selectedRole}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option != null ? option.name : ''}
                  options={roleList.map((obj) => { return obj })}
                  keyValue="name"
                  sx={{ width: '25%' }}
                  error={errors.role?.message}
                  renderInput={(params) => <Input {...params} label={t('system.users.selectRole')} />}
                  disabled={loading || validateEmail}
                />
              </Grid>
            }
            
            <Grid item xs={6}>
              <CustomTextField
                label={t('system.users.table.names')}
                value={form.name}
                onChange={e => handleChange(e, 'name')}
                sx={{ width: '100%' }}
                error={errors.name?.hasError}
                helperText={errors.name?.message}
                disabled={loading || authTypeRef || form.authenticationType || validateEmail || form.disableAD || addRole}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                label={t('system.users.table.lastNames')}
                value={form.lastName}
                onChange={e => handleChange(e, 'lastName')}
                sx={{ width: '100%' }}
                error={errors.lastName?.hasError}
                helperText={errors.lastName?.message}
                disabled={loading || authTypeRef || form.authenticationType || validateEmail || form.disableAD || addRole}
              />
            </Grid>

            <Grid item xs={12} md={2} xl={1}>
              <CustomIconButton
                ref={buttonRef}
                size="large"
                onClick={() => handleGenerateClick()}
                disabled={loading || authTypeRef || form.authenticationType || validateEmail || addRole}
              >
                <SyncLockIcon fontSize='50px' />
              </CustomIconButton>
            </Grid>

            <Grid item xs={6} md={10} xl={5}>
              <CustomTextField
                label={t('system.users.password')}
                type={'password'}
                value={form.password}
                onChange={e => handleChange(e, 'password')}
                sx={{ width: '100%' }}
                error={errors.password?.hasError}
                helperText={errors.password?.message}
                disabled={loading || authTypeRef || form.authenticationType || validateEmail || addRole}
              />
            </Grid>

            <Grid item xs={6} md={12} xl={6}>
              <CustomTextField
                label={t('system.users.passwordConfirmation')}
                type={'password'}
                value={form.confirm_password}
                onChange={e => handleChange(e, 'confirm_password')}
                sx={{ width: '100%' }}
                error={errors.confirm_password?.hasError}
                helperText={errors.confirm_password?.message}
                disabled={loading || authTypeRef || form.authenticationType || validateEmail || addRole}
              />
            </Grid>
            {passwordPolicyData.passwordSecurity &&
              <Grid item xs={12} md={12} xl={6}>
                <SpanPassword>{passwordPolicyData.passwordComments}</SpanPassword>
              </Grid>
            }

          </Grid>
          {errors.general?.hasError && (
            <SectionBottomInfo>
              <Text>{errors.general?.message}</Text>
            </SectionBottomInfo>
          )}
        </ContainerInfo>
      </Body>

      {showPasswordGenerator && (
        <PasswordGenerator
          buttonRef={buttonRef}
          onGenerate={handleGeneratePassword}
        />
      )}
    </Container>
  )
}
