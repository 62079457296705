import React, { useEffect, useState, Fragment } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

// Components
import Dropzone from 'react-dropzone'
import { ShowDialog } from '../ShowDialog';
import { Grid, IconButton, Tooltip, Typography, Checkbox } from '@mui/material';
import { toast } from 'react-toastify';
import { BackdropLoading } from '../../components/BackdropLoading';
import { SelectInput } from '../SelectInput';

// Services
import { WorkflowDataFileApi } from '../../services/WorkflowDataFileApi';
import { ChangeControlTypeApi } from '../../services/ChangeControlTypeApi';
import Router from '../../router';

// Icons
import { Cancel, Download } from '@mui/icons-material';

// Styles
import { Container, SectionFiles, CustomSpan, CustomTypography, ContainerLeft, Text, CustomGrid, StickyHeader, CustomControlLabel, CustomContainer, CustomTableContainer, CustomTableCell } from './styles';
import { CustomButton } from '../../pages/styles';

// Utils
import { ColorsContext } from "../../Context/ColorsContext"
import { CheckResponse } from '../../utils/checkResponse';
import { getIcon } from '../../utils/iconConfig';
import { UrlToFile } from '../../utils/urlToFile';
import { useTranslation } from 'react-i18next'
import { validateFiles, validateFormatFiles } from '../../utils/commonFunctions';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext';

export const Attachments = (props) => {
    const { id, disabled, userData, statusData, setAttachments } = props
    const workflowDataFileApi = new WorkflowDataFileApi();
    const changeControlTypeApi = new ChangeControlTypeApi();
    const {check}= new CheckResponse();
    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);
    const ability = useAbility(AbilityContext);

    const [files, setFiles] = useState([])
    const [newFiles, setNewFiles] = useState([])
    const [toDelete, setToDelete] = useState({})
    const ACCEPTED_FILES = {
        'image/jpeg': [], 
        'image/png': [],
        'application/pdf': [],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
    }
    const [loading, setLoading] = useState(false)
    const [dialog, setDialog] = useState({
        status: false,
        title: '',
        message: ''
    })
    const [changeControlType, setChangeControlType] = useState([])

    useEffect(() => {
        async function call() {
            await callChangeControlTypeApi()
            await callAttachments(id)
        }
        call()
    }, [])

    async function callChangeControlTypeApi() {
        let response = await changeControlTypeApi.list();
        let result = check(response);

        if (result.status) {
            setChangeControlType(response.data)
        }
    }

    async function callAttachments(id) {
        setLoading(true)

        let response = await workflowDataFileApi.getFile(id);
        let result = check(response)

        if (result.status) {
            setFiles(response.data)
            if (typeof setAttachments === 'function') setAttachments(response.data)
        }
        else {
            if (result.errors) {
                toast.error(() => 
                    <div>
                        {result.errors}
                    </div>
                );
            }
        } 

        setLoading(false)
    }

    function deleteAttach(index, isDb) {
        let array = [...files]

        if (array[index]?.id && isDb) {
            deleteFromDb(array, index)
            return
        }

        if (array.length > 0) {
            array.splice(index, 1)
            setFiles(array)
            setNewFiles(array.filter((el) => !el.id))
        }
    }

    function deleteFromDb(array, index) {
        setToDelete(array[index])
        setDialog({
            status: true,
            title: t('files.messages.deleteFile.title'),
            message: t('files.messages.deleteFile.message', {file: array[index].route?.toString().split('?')[0].toString().split(/[\/\\]/).pop()}),
            type: 'confirmation',
            index: index
        })
    }

    async function saveFiles() {
        setLoading(true)
        let validFormat = validateFormatFiles(newFiles)
        if (!validFormat) {
            setLoading(false)
            return
        }
        
        let resultCheck = await validateFiles(newFiles)
        if (!resultCheck) {
          setLoading(false)
          return
        }

        setLoading(true)
    
        let formData = new FormData()
        formData.append('workflowDataId', id)
        formData.append('statusId', statusData?.id)
        newFiles?.map(file => { formData.append("files", file) })

        const types = newFiles?.map(file => {
            const dataFile = files.find(el => el.name === file.name);
            return {
                name: file.name,
                changeControlTypeId: dataFile?.changeControlTypeId
            };
        });
        formData.append('types', JSON.stringify(types));

        const response = await workflowDataFileApi.upload(formData);
        let result = check(response)

        if (result.status) {
            setDialog({
                status: true, isClose: true,
                title: t('files.messages.saveFile.title'),
                message: t('files.messages.saveFile.message')
            })
            await callAttachments(id)
        }
        else {
            toast.error(() => 
                <div>
                    {result.errors}
                </div>
            );
        } 
    
        setLoading(false)
    }
    
    async function deleteFile(file) {
        setLoading(true)

        let data = {
            fileId: file?.id,
            workflowDataId: file?.workflowDataId
        }

        const response = await workflowDataFileApi.delete(data);
        let result = check(response)

        if (result.status) {
            setDialog({
                status: true, isClose: true,
                title: t('files.messages.deleteFile.confirm'),
                message: t('files.messages.deleteFile.confirmMessage')
            })
            await callAttachments(id)
        }
        else {
            toast.error(() => 
                <div>
                    {result.errors}
                </div>
            );
        } 
    
        setLoading(false)
    }

    function onDrop(droppedFiles) {
        setFiles((prev) => [
            ...prev, 
            ...droppedFiles.map(file => ({
                name: file.name,
                changeControlTypeId: null,
                path: file.path
            }))
        ]);
        setNewFiles(droppedFiles)
    };

    function updateProcedureState(index, itemSelected) {
        let array = [...files]

        if (array.length > 0 && array[index]) {
            array[index].changeControlTypeId = itemSelected?.id ?? null;
            array[index].changeControlType = itemSelected
            setFiles(array)
        }
    }

    function onDropRejected() {
        toast.warning(t('files.messages.errorFile.invalidFormat'))
    };

    async function handleCloseDialog(value) {
        setDialog({status: false})
        if (value) {
            await deleteFile(toDelete)
            setToDelete({})
        }
    }

    async function downloadFile(file) {
        let downloadFile = file

        if (file.id) {
            let urlToFile = new UrlToFile();
            let urlResource = file.route?.startsWith("http") ? `${(file.route)}` : `${Router.apiBaseUrl}/${(file.route)}`
            downloadFile = await urlToFile.convert(urlResource, null, false)
        }

        const url = window.URL.createObjectURL(new Blob([downloadFile]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name || file.route?.toString().split('?')[0].split(/[\/\\]/).pop());
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    const evaluateDisabled = (disabled) => {
        const profile = localStorage.getItem('profile');
        const isAdmin = profile && JSON.parse(profile)?.Role?.Id === 1;
      
        if (isAdmin) return false;
      
        return disabled;
      };

    return (
        <Container>
    <Dropzone onDrop={onDrop} onDropRejected={onDropRejected} disabled={evaluateDisabled(disabled)} accept={ACCEPTED_FILES}>
        {({ getRootProps, getInputProps }) => (
            <section>
                {ability.can("UploadWorkflowDataFile") &&
                    <SectionFiles {...getRootProps({ className: 'dropzone' })} disabled={evaluateDisabled(disabled)}>
                        <input {...getInputProps()} />
                        <CustomSpan>{t('files.filesDropDown')}</CustomSpan>
                    </SectionFiles>
                }
                {/* <CustomContainer id='asideeeeeee'> */}
                    {(files.length > 0 && ability.can("GetWorkflowDataFile")) && (
                        <CustomTableContainer>
                            <Table aria-label="attachments table">
                                <TableHead>
                                    <TableRow>
                                        <CustomTableCell>{t('workflow.attachments.table.file')}</CustomTableCell>
                                        <CustomTableCell>{t('workflow.attachments.table.user')}</CustomTableCell>
                                        <CustomTableCell>{t('workflow.attachments.table.state')}</CustomTableCell>
                                        <CustomTableCell>{t('workflow.attachments.table.procedure')}</CustomTableCell>
                                        <CustomTableCell>{t('workflow.attachments.table.action')}</CustomTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {files.sort((a, b) => {
                                        if (!a.id && !b.id) {
                                            // Si ambos no tienen "id", ordenar por nombre
                                            return a.name.localeCompare(b.name);
                                        }
                                        // Ordenar archivos sin "id" antes que los que tienen "id"
                                        return a.id ? 1 : -1;
                                    }).map((file, index) => (
                                        <TableRow key={`attachment-${index}`}>
                                            <TableCell sx={{ padding: '10px' }}>
                                                {(file.route?.toString().split('?')[0].split(/[\/\\]/).pop() || file.name)}
                                            </TableCell>
                                            <TableCell sx={{ padding: '10px' }}>
                                                    {file?.user?.email || userData}
                                            </TableCell>
                                            <TableCell sx={{ padding: '10px' }}>
                                                <ContainerLeft>
                                                    {getIcon(file?.status || statusData, undefined, undefined, true)}
                                                    <CustomTypography>{file?.status?.title || statusData?.title || ''}</CustomTypography>
                                                </ContainerLeft>
                                            </TableCell>
                                            <TableCell sx={{ padding: '10px' }}>
                                                {file?.id === undefined ? (
                                                    <SelectInput
                                                        name='workflow'
                                                        onChange={e => updateProcedureState(index, e)}
                                                        value={file.changeControlType}
                                                        keyValue="name"
                                                        options={changeControlType}
                                                        size="small"
                                                        width="100%"
                                                        disableClearable={false}
                                                        fontSize='0.75rem'
                                                    />
                                                ) : (
                                                    <CustomTypography>{file.changeControlType?.name || ''}</CustomTypography>
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ padding: '10px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', textAlign: 'center' }}>
                                                    <Tooltip title={t('common.download')}>
                                                        <IconButton onClick={() => downloadFile(file)}>
                                                            <Download sx={{ color: colors.buttonActionColor }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {((file?.path !== undefined || (file.workflowDataId !== null && file.workflowFormDataId === null)) &&
                                                        (file?.id === undefined || (file?.id !== undefined && file.canDelete && !file.isProcedure)) &&
                                                        ability.can("DeleteWorkflowDataFile")) && (
                                                        <Tooltip title={t('files.remove')}>
                                                            <span>
                                                                <IconButton onClick={() => deleteAttach(index, !!file.id)} disabled={evaluateDisabled(disabled)}>
                                                                    <Cancel sx={{ color: colors.red }} />
                                                                </IconButton>
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </CustomTableContainer>
                    )}
                {/* </CustomContainer> */}
            </section>
        )}
    </Dropzone>
    {ability.can("UploadWorkflowDataFile") &&
        <Grid container justifyContent='center' alignItems='center'>
            <Grid item xs={2}></Grid>
            <Grid item xs={8}>
                <CustomButton disabled={evaluateDisabled(disabled) || loading} variant='contained' type="accept" sx={{ width: '100%', marginTop: 2 }} onClick={saveFiles}>
                    {t('files.save')}
                </CustomButton>
            </Grid>
            <Grid item xs={2}></Grid>
        </Grid>
    }
    <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog} />
    <BackdropLoading open={loading} />
</Container>
    )
}