import {styled} from "@mui/material/styles";
import { TableContainer } from "@mui/material";
import { useColors } from "../../../../Context/ColorsContext"
import TableBody from "@mui/material/TableBody";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { IconButton, SearchIcon, TableCell } from "@mui/material";

import { MediaQuerys } from "./../../../../styles/GlobalStyle";


const { mobile, tablet, desktop } = MediaQuerys;

function setColor(code) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { colors } = useColors();
  if (code === '1') return colors.purple
  if (code === '2') return colors.blue
  if (code === '3') return colors.red
}

export const CustomTableCell = styled(TableCell)(() => {
  const { colors } = useColors();
  return {
      color:colors.detailTextColor,
      fontSize:'1rem',
      fontWeight:600,
      paddingTop:'10px !important',
      paddingBottom:'10px !important',
      alignItems:'center',
      [desktop]: {
        fontSize:'0.75rem !important',
      }
  }
})

export const TableCellStatus = styled(TableCell)(() => ({
    display:'flex',
    alignItems: 'center',
    [desktop]: {
      fontSize: '0.75rem'
    }

}))
export const ContainerCell = styled('div')(() => ({
    display:'flex',
    alignItems:'center',
}))

export const Figure = styled('figure')(({code}) => ({
  display:'flex',
  justifyContent:'center',
  alignItems:'center',
  background: code,
  height:'30px',
  width:'30px',
  minWidth:'30px',
  borderRadius:'50%',
  marginRight:'10px !important',
}))


export const SectionInfo = styled('section')(() => ({
    display:'flex',
    flexDirection:'column'
}))

export const InfoCell = styled('div')(() => ({
}))

export const Label = styled('label')(() => ({
  fontSize:'14px',
  paddingRight:'5px',
  [desktop]: {
    fontSize:'0.75rem',
  },
  [tablet]: {
    fontSize: '10px'
  },
  [mobile]:{
    paddingRight:'0px',
  }
}))

export const InputDate = styled('input')(({theme}) => {
  const { colors } = useColors();
  return {
    marginRight:'20px',
    borderBottom:'1px solid' + colors.grayFont,
    borderRadius:'3px',
    fontFamily: theme.typography.fontFamily,
    padding:'5px 10px',
    width:'150px',
    [desktop]: {
      width:'130px',
    },
    [tablet]: {
      width: '100px',
      height: 20,
    },
    [mobile]: {
      width: '80px',
      height: 20,
      padding: '5px 5px',
      marginRight:'10px',
  
    }
  }
})

export const FigureHead = styled('figure')(() => ({
  display:'flex'
}))

export const CleanIcon = styled(CleaningServicesIcon)(() => {
  const { colors } = useColors();
  return {
    color: colors.white,
    fontSize: 18,
    [tablet]: {
      width: 17,
      height: 17,
      padding: '1px',
      '.MuiSvgIcon-root': {
        width: 16,
        height: 16,
      }
    }
  }
})

export const IconAction = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    borderRadius: 20, 
    pointerEvents: 'pointer', 
    background: colors.main,
    marginLeft: 5
  }
})

export const NewIcon = styled(AddCircleIcon)(() => {
  const { colors } = useColors();
  return {
    color: colors.detailTextColor,
    fontSize: 18
  }
})

export const TableContent = styled(TableBody)(() => ({
  ".MuiTableCell-root": {
    fontSize: "1rem !important",
    [desktop]: {
        fontSize: "0.75rem !important",
    },
    [mobile]: {
      fontSize: "0.70rem !important",
    },
  },
  [tablet]: {
    '.MuiTablePagination-displayedRows': {
      fontSize: '0.75rem !important',
    },
    '.MuiTablePagination-selectLabel':{
      fontSize: '0.75rem!important',
    }
  },
}));

export const ContainerInputsDate = styled('div')(() => ({
  display:'flex',
  flexDirection:'row',
  alignItems:'center',
  gap: '0.25rem',
}))

export const ContainerGroup = styled('div')(() => ({
  display:'flex',
  flexDirection: 'row',
}))

export const ContainerSearch = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
}))

export const ContainerContentHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: 'inherit',
}))

export const Forms = styled('form')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [tablet]: {
    justifyContent: 'flex-end',
  },
  [mobile]: {
    height: 'auto',
    marginTop: '5px',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
  }
}))

export const CustomIconButton = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    display: 'none',
    borderRadius: 20,
    pointerEvents: "pointer",
    background: colors.main,
    marginLeft: 5,
    "&:hover": {
      backgroundColor: colors.mainClear,
      color: colors.white,
    },
    [desktop]: {
      fontSize: "0.25rem",
    },
    [tablet]: {
      height: 20,
      width: 20
    },
    [mobile]: {
      display: "block",
    }
  }
})

export const CustomTableContainer = styled(TableContainer)(() => {
  return {
    height: 'calc(100vh - 340px)'
  }
})