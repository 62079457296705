import { styled } from '@mui/material/styles';
import { FormControl, InputLabel, Badge, Select, MenuItem, Card, Checkbox } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useColors } from '../../Context/ColorsContext';

import { MediaQuerys } from '../../styles/GlobalStyle';

const { mobile, tablet, desktop } = MediaQuerys;


export const Container = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.50rem',
        height: '100%',
        width: '100%',
    }
})

export const ContainerTopGrahps = styled('div')(({theme}) => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.50rem',
        height: '50%',
        width: '100%',
        [desktop]: { 
            flexWrap: 'wrap',
        }
    }
});

export const ContentGraphics = styled(Card)(({ width, height }) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        height: height,
        width: width,
        padding: '10px',
        [desktop]: {
            flex: 1,
            minWidth: '300px',
        }
    }
});

export const ContainerGraphics = styled('div')(({ width, height }) => {
    return {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        height: '100%',
        '&::-webkit-scrollbar': {
            height: '5px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        } 
    }
});

export const ContainerBottomGrahps = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.50rem',
        height: '50%',
        width: '100%',
        [desktop]: {
            flexWrap: 'wrap',
        }
    }
});

export const ContainerTreeMap = styled('div')(() => {
    return {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.50rem',
    }
});

export const CustomTitleGraphics = styled(Typography)(() => {
    return {
        marginLeft: '1rem',
        fontWeight: 'bold',
        fontSize: '14px',
    }
})



