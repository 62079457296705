import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class SecurityApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async login(login) {
        return await axios.post(
            `${Router.apiBaseUrl}${Router.apiLogin}`, 
            { ...login }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async signup(user) {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.post(
            `${Router.apiBaseUrl}${Router.apiSignup}`, 
            {...user},
            { headers: auth_token }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async logout() {
        let auth_token = { 'Authorization': `Bearer ${this.token}`}

        return await axios.get(
            `${Router.apiBaseUrl}${Router.apiLogout}`, 
            { headers: auth_token }
        ).then(async response => response).catch(async error => error)
    }

    async checkLogin(login) {
        return await axios.post(
            `${Router.apiBaseUrl}${Router.apiCheckLogin}`, 
            { ...login }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async loginAD(login) {
        return await axios.post(
            `${Router.apiBaseUrl}${Router.apiLoginAD}`, 
            { ...login }
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async userRoles(email) {
        return await axios.get(
            `${Router.apiBaseUrl}${Router.apiUserRoles}?username=${encodeURIComponent(email)}`
        )
        .then(async response => {
            return await this.healthCheck.checkApi(response);
        })
        .catch(async error => {
            return await this.healthCheck.checkApi(error);
        });
    }
    
}