import { styled } from "@mui/material/styles";
import { IconButton, TableCell, Toolbar, TableContainer } from "@mui/material";
import Box from "@mui/material/Box";
import { useColors } from "../../../../Context/ColorsContext";
import TableBody from "@mui/material/TableBody";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FormControl from "@mui/material/FormControl";
import Select from '@mui/material/Select';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";


import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

export const CustomTableCell = styled(TableCell)(() => {
  const { colors } = useColors();
  return {
    color: colors.buttonActionColor,
    fontSize: "1rem",
    fontWeight: 600,
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    alignItems: "center",
    padding: "0 16px",
    "&:hover": {
      color: colors.buttonActionColor,
      fontWeight: 700,
    },
    [desktop]: {
      fontSize: "0.75rem !important",
    },
  };
});

export const TableCellStatus = styled(TableCell)(() => ({
  display: "flex",
  alignItems: "center",
  border: "none",
}));

export const ContainerCell = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

export const Figure = styled("figure")(({ title }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: title,
  height: "30px",
  width: "30px",
  minWidth: "30px",
  borderRadius: "50%",
  marginRight: "10px !important",
}));

export const SectionInfo = styled("section")(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const InfoCell = styled("div")(() => ({}));

export const Label = styled("label")(() => ({
  fontSize: "14px",
  paddingRight: "5px",
  [desktop]: {
    fontSize: '0.75rem',
  },
  [tablet]: {
    fontSize: '10px'
  },
  [mobile]: {
    paddingRight: '0px',
  }
}));

export const InputDate = styled("input")(({ theme }) => {
  const { colors } = useColors();
  return {
    borderBottom: "1px solid " + colors.grayFont,
    borderRadius: "3px",
    fontFamily: theme.typography.fontFamily,
    padding: "5px 10px",
    width: "150px",
    [desktop]: {
      width: '130px',
    },
    [tablet]: {
      width: '100px',
      height: 20,
    },
    [mobile]: {
      width: '80px',
      height: 20,
      padding: '5px 5px',
      // marginRight: '10px',
    }
  };
});

export const FigureHead = styled("figure")(() => ({
  display: "flex",
}));

export const Cell = styled(TableCell)(({ data }) => {
  const { colors } = useColors();

  let position = '';
  let sticky;

  switch (data) {
    case 'nit':
    case 'identification':
    case 'code':
    case 'id':
    case 'statusId':
      position = 'left';
      sticky = true;
      break;
    case 'actions':
      position = 'right';
      sticky = true;
      break;
    default:
      break;
  }

  return {
    position: sticky && 'sticky',
    background: sticky && colors.white,   
    border: "none",
    width: "max-content",
    [position]: sticky && 0
  }
});

export const IconAction = styled(IconButton)(({ idListDetail }) => {
  const { colors } = useColors();
  return {
    borderRadius: 20,
    pointerEvents: "pointer",
    background: colors.buttonActionColor,
    marginLeft: 5,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    },
    [desktop]: {
      fontSize: "0.25rem",
      width: '28px',
      height: '28px',
      "&MuiSvgIcon-root": {
        width: '16px'
      }
    },
    [tablet]: {
      height: 28,
      width: 28,
    },
    [mobile]: {
      // display: idListDetail ? 'flex' : 'none',
    },
  };
});

export const ContainerTest = styled('div')(({ isReqButtonAdd }) => {
  const { colors } = useColors();
  return {
    width: '20px',
    height: '20px',
    visibility: isReqButtonAdd && 'hidden',
    borderRadius: '50%',
    background: colors.white,
  }
})

export const CleanIcon = styled(CleaningServicesIcon)(() => {
  const { colors } = useColors();
  return {
    color: colors.white,
    fontSize: 18,
    [tablet]: {
      width: 17,
      height: 17,
      padding: '1px',
      '.MuiSvgIcon-root': {
        width: 16,
        height: 16,
      }
    },
    [desktop]: {
      width: '16px',
      height: '16px',
    }
  };
});

export const NewIcon = styled(AddCircleIcon)(() => {
  const { colors } = useColors();
  return {
    height: '20px',
    width: '20px',
    color: colors.white,
    // fontSize: 18,
    [desktop]: {
      height: '16px',
      width: '16px'
    }
  };
});

export const CustomIconButton = styled(IconButton)(() => {
  const { colors } = useColors();
  return {
    borderRadius: "10%",
    backgroundColor: colors.background,
    color: colors.buttonActionColor,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.background,
    },
  };
});

export const CustomToolbar = styled(Toolbar)(({ isStandardManagement, isList, isMywork }) => {
  const { colors } = useColors();
  return {
    display: isStandardManagement && 'none',
    background: colors.gray,
    borderRadius: "5px 5px 0 0",
    minHeight: "60px !important",
    marginTop: "16px",
    [tablet]: {
       minHeight: "40px !important",
    },
    [mobile]: {
      display: isList && 'none',
      paddingTop: isMywork && "5px !important",
      paddingBottom: isMywork && "5px !important"
    }
  };
});

export const CustomLink = styled("span")(() => {
  const { colors } = useColors();
  return {
    cursor: "pointer",
    color: colors.buttonActionColor,
  };
});

export const IconArea = styled("div")(({ grouped }) => {
  const { colors } = useColors();
  return {
    marginLeft: 2,
    width: 15,
    height: 15,
    fontSize: grouped ? 10 : 11,
    fontWeight: "bold",
    color: grouped ? colors.white : colors.detailTextColor,
    background: grouped ? colors.buttonActionColor : colors.orange,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export const Forms = styled("form")(({ isList, idListDetail, isFormV2, isForm }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: isFormV2 || isForm ? 'space-between' : (isList ? 'space-between' : (idListDetail ? "flex-start" : "flex-end")),
  [tablet]: {
    justifyContent: isFormV2 || isForm ? 'space-between' : (isList ? 'space-between' : (idListDetail ? "flex-start" : "flex-end")),
  },
  [mobile]: {
    height: "auto",
    // marginTop: "5px",
    alignItems: idListDetail ? 'center' : 'flex-start',
    flexWrap: "nowrap",
  },
}));



export const TableContent = styled(TableBody)(() => ({
  ".MuiTableCell-root": {
    fontSize: "1rem !important",
    [desktop]: {
      fontSize: "0.75rem !important",
    },
    [mobile]: {
      fontSize: "0.70rem !important",
    },
  },
  [tablet]: {
    ".MuiTablePagination-displayedRows": {
      fontSize: "0.75rem !important",
    },
    ".MuiTablePagination-selectLabel": {
      fontSize: "0.75rem!important",
    },
  },
}));


export const ContainerAddIcon = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'row',
  }
})


export const ClearIconButton = styled(IconButton)(({ isFirstClearButton }) => {
  const { colors } = useColors();
  return {
    display: isFirstClearButton ? "none" : "",
    borderRadius: 20,
    pointerEvents: 'pointer',
    background: colors.buttonActionColor,
    color: colors.white,
    marginLeft: 5,
    "&:hover": {
      backgroundColor: colors.buttonActionColor,
      opacity: 0.9,
      color: colors.white,
    },
    [desktop]: {
      fontSize: "0.25rem",
    },
    [tablet]: {
      height: 20,
      width: 20,
    },
    [mobile]: {
      display: "inline-flex",
    },
  }
})


//?? MultiSelect Containers
export const CustomFormControl = styled(FormControl)(() => {
  return {
    width: '150px',
    [desktop]: {
      width: '130px',
      '& .MuiFormLabel-root': {
        fontSize: '0.75rem',
      }
    },
    [tablet]: {
      width: '110px',
      height: 20,
    },
    // [mobile]: {
    //   width: '80px',
    //   height: 20,
    // }
  }
})

export const ContainerGroupSelects = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    justifyContent: 'center',
    alignItems: 'center',
  }
})

export const ContainerFiltros = styled('div')(({ isPending }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    [mobile]: {
      flexDirection: 'column',
      gap: isPending && '0.65rem'
    }

  }
})


export const ContainerSearch = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '2px',
  alignItems: 'center',
}))



export const ContainerInputsDate = styled('div')(({ isMywork }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.25rem',
  [desktop]: {
    flexDirection: isMywork && 'column'
  },
  [mobile]: {
    flexDirection: 'row'
  }
}))


export const ContainerGroup = styled('div')(({ needMagin, alignStart, center, needSpeedDial }) => {
  const { colors } = useColors();

  return {
    display: 'flex',
    alignItems: alignStart ? 'flex-start' : 'center',
    // justifyContent: center ? 'center' : 'space-between',
    justifyContent: center ? 'center' : 'flex-start',
    gap: '0.50rem',
    flexDirection: 'row',
    marginLeft: needMagin && 5,
    [mobile]: {
      display: needSpeedDial && 'none',
    }
  }
})

export const CustomBox = styled(Box)(({ hideFilterDate, isList }) => {
  return {
    width: '100%',
    marginTop: hideFilterDate && '16px',
    [mobile]: {
      marginTop: isList && '16px'
    }
  }
})

export const CustomSelect = styled(Select)(() => {
  return {
    width: '150px',
    height: '32px',
    [desktop]: {
      width: '130px',
      height: 26
    },
    [tablet]: {
      width: '110px',
      height: 20,
    },
    // [mobile]: {
    //   width: '80px',
    //   height: 20,
    // }
  }
})


export const CustomInputLabel = styled(InputLabel)(() => {
  return {
    fontSize: "0.8em",
    position: "absolute",
    top: "50%",
    left: "40%",
    transform: "translate(-50%, -50%)",
  }
})


export const CustomMenuItem = styled(MenuItem)(() => {
  return {
    [desktop]: {
      '& .MuiTypography-root': {
        fontSize: '0.75rem',
      }
    }
  }
})


export const CustomBadge = styled(Badge)(() => {
  return {
    color: "white",
    [desktop]: {
      '& .MuiBadge-badge': {
        minWidth: '15px !important',
        width: '15px !important',
        height: '15px !important',
      },
      fontSize: '0.75rem',
    }
  }
})

export const ContainerDates = styled('div')(() => {
  return {
    display: "flex",
    flexDirection: 'row',
    gap: '1rem',
  }
})

export const ContainerActions = styled('div')(({needSpeedDial}) => {
  return {
    // display: "flex",
    // flexDirection: 'row',
    // gap: '0.35rem',
    [mobile]: {
      display: needSpeedDial && 'none',
    }
  }
})

export const ContainerSpeedDial = styled('div')(({ needSpeedDial }) => {
  return {
    display: 'none',
    width: 'auto',
    height: 'auto',
    [mobile]: {
      display: needSpeedDial && 'block',
    }
  }
})

export const CustomTableContainer = styled(TableContainer)(() => {
  return {
    height: 'calc(100vh - 340px)'
  }
})