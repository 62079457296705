import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";
import { Route } from "react-router-dom";

export class DashboardReportApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async getByWorkflow(created, statusId) {
        return await axios.get(Router.apiBaseUrl + Router.apiDashboardByWorkflow, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
            params: {
                created: created,
                statusId: statusId
            }
        })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
    }

    async getByPlotPlan(plotPlanId, workflowId, created, statusId) {
        return await axios.get(Router.apiBaseUrl + Router.apiDashboardByPlotPlan, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
            params: {
                plotPlanId: plotPlanId,
                workflowId: workflowId,
                created: created,
                statusId: statusId
            }
        })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
    }

    async getByStatus({ plotPlanId, workflowId, created, areaTypeId }) {
        return await axios.get(Router.apiBaseUrl + Router.apiDashboardByStatus, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
            params: {
                plotPlanId: plotPlanId,
                workflowId: workflowId,
                created: created,
                areaTypeId: areaTypeId,
            },
            paramsSerializer: { indexes: null }
        })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
    }

    async getByCreated(plotPlanId, workflowId, statusId) {
        return await axios.get(Router.apiBaseUrl + Router.apiDashboardByCreated, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
            params: {
                plotPlanId: plotPlanId,
                workflowId: workflowId,
                statusId: statusId
            }
        })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
    }

    async downloadReport(plotPlanId, workflowId, statusId, created) {
        return await axios.get(Router.apiBaseUrl + Router.apiDashboardDownloadExcel, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
            responseType: 'blob',
            params: {
                plotPlanId: plotPlanId,
                workflowId: workflowId,
                statusId: statusId,
                created: created
            }
        })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
    }

    async getTable(plotPlanId, workflowId, statusId, created) {
        return await axios.get(Router.apiBaseUrl + Router.apiDashboardTable, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            },
            params: {
                plotPlanId: plotPlanId,
                workflowId: workflowId,
                statusId: statusId,
                created: created
            }
        })
            .then(async response => {
                return await this.healthCheck.checkApi(response)
            }).catch(async error => {
                return await this.healthCheck.checkApi(error)
            })
    }

    async getPlantManagementReport() {
        return await axios.get(Router.apiBaseUrl + Router.apiPlantManagementReport, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        }).then(async response => {
            return await this.healthCheck.checkApi(response);
        }).catch(async error => {
            return await this.healthCheck.checkApi(error);
        })
    }

    async getPlantManagementCompany() {
        return await axios.get(Router.apiBaseUrl + Router.apiPlantManagementCompany, {
            headers: {
                'Authorization': `Bearer ${this.token}`
            }
        }).then(async response => {
            return await this.healthCheck.checkApi(response);
        }).catch(async error => {
            return await this.healthCheck.checkApi(error);
        })
    }

    async sendManagementReport(params) {
        try {
            const response = await axios.post(
                Router.apiBaseUrl + Router.apiManagementReport, 
                {
                    integrationKey: params.integrationKey,
                    initialDate: params.initialDate,
                    finalDate: params.finalDate,
                    managementIds: params.managementIds,
                    trunkIds: params.trunkIds,
                    plantIds: params.plantIds
                },
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return await this.healthCheck.checkApi(response);
        } catch (error) {
            return await this.healthCheck.checkApi(error);
        }
    }


    async sendCompanyReport(params) {
        try {
            const response = await axios.post(
                Router.apiBaseUrl + Router.apiReportCompany, 
                {
                    integrationKey: params.integrationKey,
                    initialDate: params.initialDate,
                    finalDate: params.finalDate,
                    managementIds: params.managementIds,
                    trunkIds: params.trunkIds,
                    plantIds: params.plantIds,
                    contractIds: params.contractIds,
                    companyIds: params.companyIds
                },
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return await this.healthCheck.checkApi(response);
        } catch (error) {
            return await this.healthCheck.checkApi(error);
        }
    }

    async sendListDeviation(params) {
        try {
            const response = await axios.post(
                Router.apiBaseUrl + Router.apiListDeviations, 
                {
                    integrationKey: params.integrationKey,
                    initialDate: params.initialDate,
                    finalDate: params.finalDate,
                    managementIds: params.managementIds,
                    trunkIds: params.trunkIds,
                    plantIds: params.plantIds,
                    contractIds: params.contractIds,
                    companyIds: params.companyIds
                },
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            return await this.healthCheck.checkApi(response);
        } catch (error) {
            return await this.healthCheck.checkApi(error);
        }
    }

    async dashboardReportUp(params) {
        try {
            const response = await axios.post(
                Router.apiBaseUrl + Router.apiDashboardGeneral,
                {
                    managementIds: params.managementIds,
                    trunkIds: params.trunkIds,
                    plantIds: params.plantIds,
                    companyIds: params.companyIds,
                    contractIds: params.contractIds,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return await this.healthCheck.checkApi(response);
        }catch (error) {
            return await this.healthCheck.checkApi(error);
        }
    }

    async dashGeneralCritical(params) {
        try {
            const response = await axios.post(
                Router.apiBaseUrl + Router.apiDashboardGeneralCritical,
                {
                    managementIds: params.managementIds,
                    trunkIds: params.trunkIds,
                    plantIds: params.plantIds,
                    companyIds: params.companyIds,
                    contractIds: params.contractIds,
                },
                {
                    headers: {
                        'authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return await this.healthCheck.checkApi(response);
        }catch (error) {
           return await this.healthCheck.checkApi(error); 
        }
    }

    async dashGeneralHistorical(params) {
        try {
            const response = await axios.post(
                Router.apiBaseUrl + Router.apiDashboardGeneralHistorical,
                {
                    managementIds: params.managementIds,
                    trunkIds: params.trunkIds,
                    plantIds: params.plantIds,
                    companyIds: params.companyIds,
                    contractIds: params.contractIds,
                    year: params.year,
                    month: params.month,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    }
                }
            ); 
            return await this.healthCheck.checkApi(response);
        }catch (error) {
            return await this.healthCheck.checkApi(error);
        }
    }

    async dashGeneralColdHot(params) {
        try {
            const response = await axios.post(
                Router.apiBaseUrl + Router.apiDashboardGeneralColdHot,
                {
                    managementIds: params.managementIds,
                    trunkIds: params.trunkIds,
                    plantIds: params.plantIds,
                    companyIds: params.companyIds,
                    contractIds: params.contractIds,
                },
                {
                    headers: {
                        'authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    }
                }
            );
            return await this.healthCheck.checkApi(response);
        }catch (error) {
            return await this.healthCheck.checkApi(error);
        }
    }


}