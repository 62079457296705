import React, { useState, useRef } from 'react'
import { PasswordSection } from './PasswordSection';
import { RoleSection } from './RoleSection';
import { UserNameSection } from './UsernameSection';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Link } from 'react-router-dom';
import Recaptcha from 'react-google-recaptcha';
import Router from '../../../../router';
import { Button } from '@mui/material';

import { SecurityApi } from '../../../../services/SecurityApi';
import { useMsal } from "@azure/msal-react";

import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { loginRequest } from "../../../../utils/msal/authConfig"


// Styles
import {
  Container,
  Form,
  Title,
  Subtitle,
  SectionRow,
  SectionInput,
  ContainerInput,
  Input,
  Span,
  SpanLink,
  SectionBtn,
  LabelCustom
} from './styles'

import { useTranslation } from 'react-i18next'
import { ColorsContext } from "../../../../Context/ColorsContext"

export const LoginForm = ({
  form,
  setForm,
  messageError,
  handleChange,
  handleSubmit,
  isLoading,
  recaptchaRef,
  setMessageError,
  setIsLoading,
  setOpen,
}) => {

  const { instance } = useMsal();
  const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const [arrRolesUser, setArrRolesUser] = React.useState([]);
  const [showPassword, setShowPassword] = React.useState(false);
  const [step, setStep] = useState(1);
  const securityApi = new SecurityApi();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    event.persist();

    setTimeout(() => {
      handleClickShowPassword();
    }, 0);
  };

  const handleRecaptchaVerify = (response) => {
    setForm({ ...form, reCaptchaToken: response })
  };

  const getLoginIcon = () => {

    if (colors?.loginIconUrl === null || colors?.loginIconUrl === undefined || colors?.loginIconUrl === "") {
      return (
        <img src='cenit-logo.png' width={300} alt='logo' />
      )
    }

    return (colors?.loginIconUrl !== null && colors?.loginIconUrl !== "" && colors?.loginIconUrl !== undefined) ?
      <img src={`${Router.apiBaseUrl}/${colors.loginIconUrl.toString()}`} width={300} alt='logo' />
      : null
  }

  const getRoleUsers = async (email) => {
    const resp = await securityApi.userRoles(email);
    if (resp.status === 200) {
      return resp.data;
    }
  };

  const checkLogin = async (roleId) => {
    const resp = await securityApi.checkLogin(form);

    if (resp?.status === 200) {
      let isAdUser = resp?.data?.data?.isADUser ?? false;
      setMessageError('');
      if (isAdUser) {
        localStorage.setItem('userLogin', form.username);
        localStorage.setItem("roleId", roleId);
        loginRequest.redirectStartPage = Router.appSigninAzure;
        instance.loginRedirect(loginRequest).catch((e) => {
          // console.error(e);
        });
      } else {
        setStep(3);
      }
    } else {
      setMessageError(
        resp?.response?.data?.description ||
        resp?.response?.data?.data?.description ||
        'Error en autenticación'
      );
    }
  }


  const nextStep = async () => {
    setIsLoading(true);
    setMessageError('');

    if (step === 1) {
      if (!form.username) {
        setMessageError(t('password.validations.mandatory'));
        setIsLoading(false);
        return;
      }

      let resp = await getRoleUsers(form.username);
      if(!resp.length) {
        setOpen(true);
        setIsLoading(false);
        return;
      }
      setArrRolesUser(resp);

      if (resp.length >= 2) {
        setStep(2);
      } else {
        const [{ id }] = resp;
        setForm((prev) => ({ ...prev, roleId: id }))
        await checkLogin(id);
      }

      setIsLoading(false);
      return;
    }

    if (step === 2) {
      if (!form.roleId) {
        setMessageError(t('password.validations.role'));
        setIsLoading(false);
        return;
      }
      await checkLogin(form.roleId);
    }
    setIsLoading(false);
  };

  const backStep = () => {
    setMessageError('');
    setForm({ username: '', roleId: null, password: '', reCaptchaToken: null })
    if(arrRolesUser.length >= 2){
      setStep((prevStep) => prevStep - 1)
    }else {
      setStep((prevStep) => prevStep - 2)
    }
  }

  return (
    <Container>
      <Form>
        {getLoginIcon()}
        <Title>{(colors?.title !== null && colors?.title !== "" && colors?.title !== undefined ? colors.title : t('login.titleApp'))}</Title>
        <Subtitle>{t('login.title')}</Subtitle>

        {step === 1 &&
          <UserNameSection
            handleChange={handleChange}
            isLoading={isLoading}
            colors={colors}
            messageError={messageError}
            step={step}
            nextStep={nextStep}
          />
        }

        {step === 2 &&
          <RoleSection
            form={form}
            colors={colors}
            arrRolesUser={arrRolesUser}
            messageError={messageError}
            isLoading={isLoading}
            handleChange={handleChange}
            step={step}
            nextStep={nextStep}
            backStep={backStep}
          />
        }

        {step === 3 &&
          <PasswordSection
            showPassword={showPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            recaptchaRef={recaptchaRef}
            handleRecaptchaVerify={handleRecaptchaVerify}
            messageError={messageError}
            handleSubmit={handleSubmit}
            form={form}
            isLoading={isLoading}
            handleChange={handleChange}
            colors={colors}
            step={step}
            backStep={backStep}
          />
        }

        {step === 3 &&
          <SectionRow>
            <Link to={Router.appPasswordRecovery} state={{ user: form.username }}>
              <SpanLink>{t('login.forgotPassword')}</SpanLink>
            </Link>
          </SectionRow>
        }
      </Form>
    </Container>
  )
}
