import { useTranslation } from 'react-i18next'
import Recaptcha from 'react-google-recaptcha';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import {

    SectionRow,
    SectionInput,
    ContainerInput,
    Input,
    Span,
    SectionBtn,
    LabelCustom
} from './styles'



export const PasswordSection = ({
    showPassword,
    handleClickShowPassword,
    handleMouseDownPassword,
    recaptchaRef,
    handleRecaptchaVerify,
    messageError,
    handleChange,
    handleSubmit,
    form,
    isLoading,
    colors,
    backStep,
}) => {

    const { t } = useTranslation();

    return (
        <>
            <SectionRow>
                <SectionInput bottom>
                    <LabelCustom>{t('login.password')}:</LabelCustom>
                    <ContainerInput>
                        <Input
                            name='password'
                            onChange={(e) => handleChange(e, 'password')}
                            required
                            type={showPassword ? 'text' : 'password'}
                        />
                        <InputAdornment>
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    </ContainerInput>
                </SectionInput>
            </SectionRow>

            {process.env.REACT_APP_CAPTCHA !== undefined &&
                <SectionRow>
                    <div style={{ margin: '0 auto' }}>
                        <Recaptcha ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA} onChange={handleRecaptchaVerify} />
                    </div>
                </SectionRow>
            }

            <SectionRow>
                <SectionInput>
                    <Span>{messageError}</Span>
                </SectionInput>
            </SectionRow>

            <SectionBtn double>
                <div>
                    <Button
                        variant="contained"
                        sx={{
                            color: colors.white,
                            backgroundColor: colors.buttonActionColor,
                            cursor: 'pointer',
                            padding: '.5em 2em',
                            borderRadius: '5px',
                            display: 'inline-block',
                            justifyContent: 'center',
                            transition: 'all .3s ease-in-out',
                            margin: '2px 0',
                            width: '40%',
                            "&.MuiButton-outlined.Mui-disabled": {
                                background: colors.grayGraphic,
                                cursor: 'not-allowed',
                            },
                            "&:hover": {
                                backgroundColor: colors.buttonActionColor,
                                opacity: 0.8,
                                color: colors.white,
                                transition: 'all .3s ease',
                            },
                            '&:active': {
                                transition: 'all .3s ease',
                                opacity: '1',
                            },
                        }}
                        onClick={() => handleSubmit()}
                        disabled={isLoading || form.reCaptchaToken === null || form.password === ''}
                    >
                        {t('login.login')}
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        sx={{
                            color: colors.buttonActionColor,
                            backgroundColor: colors.white,
                            border: `1px solid ${colors.buttonActionColor}`,
                            cursor: 'pointer',
                            padding: '.5em 2em',
                            borderRadius: '5px',
                            display: 'inline-block',
                            justifyContent: 'center',
                            transition: 'all .3s ease-in-out',
                            margin: '2px 0',
                            width: '40%',
                            "&.MuiButton-outlined.Mui-disabled": {
                                background: colors.grayGraphic,
                                cursor: 'not-allowed',
                            },
                            "&:hover": {
                                backgroundColor: colors.white,
                                opacity: 0.8,
                                color: colors.buttonActionColor,
                                transition: 'all .3s ease',
                            },
                            '&:active': {
                                transition: 'all .3s ease',
                                opacity: '1',
                            },
                        }}
                        onClick={() => backStep()}
                        disabled={isLoading}
                    >
                        {t('login.stepper.back')}
                    </Button>
                </div>
            </SectionBtn>
        </>
    )
}