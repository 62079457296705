

import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    SectionRow,
    SectionInput,
    Span,
    SectionBtn,
} from './styles'

export const RoleSection = ({
    colors,
    form,
    arrRolesUser,
    messageError,
    isLoading,
    handleChange,
    nextStep,
    backStep,
}) => {

    const { t } = useTranslation();

    return (
        <>
            <SectionRow>
                <SectionInput bottom>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="demo-select-small-label">{t('login.role')}</InputLabel>
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={form.roleId}
                            label={t('login.role')}
                            onChange={(e) => handleChange(e, 'roleId')}
                        >
                            {arrRolesUser?.map((item) => (
                                <MenuItem key={`${item.id}-${item.name}`} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </SectionInput>
            </SectionRow>

            <SectionRow>
                <SectionInput>
                    <Span>{messageError}</Span>
                </SectionInput>
            </SectionRow>

            <SectionBtn double>
                <div>
                    <Button
                        variant="contained"
                        sx={{
                            color: colors.white,
                            backgroundColor: colors.buttonActionColor,
                            cursor: 'pointer',
                            padding: '.5em 2em',
                            borderRadius: '5px',
                            display: 'inline-block',
                            justifyContent: 'center',
                            transition: 'all .3s ease-in-out',
                            width: '40%',
                            "&.MuiButton-outlined.Mui-disabled": {
                                background: colors.grayGraphic,
                                cursor: 'not-allowed',
                            },
                            "&:hover": {
                                backgroundColor: colors.buttonActionColor,
                                opacity: 0.8,
                                color: colors.white,
                                transition: 'all .3s ease',
                            },
                            '&:active': {
                                transition: 'all .3s ease',
                                opacity: '1',
                            },
                        }}
                        onClick={() => nextStep()}
                        disabled={isLoading}
                    >
                        {t('login.stepper.next')}
                    </Button>
                </div>
                <div>
                    <Button
                        variant="contained"
                        sx={{
                            color: colors.buttonActionColor,
                            backgroundColor: colors.white,
                            border: `1px solid ${colors.buttonActionColor}`,
                            cursor: 'pointer',
                            padding: '.5em 2em',
                            borderRadius: '5px',
                            display: 'inline-block',
                            justifyContent: 'center',
                            transition: 'all .3s ease-in-out',
                            margin: '2px 0',
                            width: '40%',
                            "&.MuiButton-outlined.Mui-disabled": {
                                background: colors.grayGraphic,
                                cursor: 'not-allowed',
                            },
                            "&:hover": {
                                backgroundColor: colors.white,
                                opacity: 0.8,
                                color: colors.buttonActionColor,
                                transition: 'all .3s ease',
                            },
                            '&:active': {
                                transition: 'all .3s ease',
                                opacity: '1',
                            },
                        }}
                        onClick={() => backStep()}
                        disabled={isLoading}
                    >
                        {t('login.stepper.back')}
                    </Button>
                </div>
            </SectionBtn>




        </>
    )
}