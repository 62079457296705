import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';

import {
  CustomTableCell,
  FigureHead,
  Text,
  TableContent,
  Label,
  InputDate,
  GroupAction,
  ContainerGroup,
  IconArea,
  CustomTyphography,
  CustomTableContainer
} from './styles';

import { ColorsContext } from "../../../../Context/ColorsContext"
import { IconsButtonsTable } from '../../../../pages/styles';
import { LoadingProgress } from '../../../LoadingProgress';
import { StickyColumn, CustomCell } from '../../../../styles/GlobalStyle';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Utils
import { useTranslation } from 'react-i18next';
import { MediaQuerys } from '../../../../styles/GlobalStyle';
import { returnStringContract, returnStringPlants } from '../../../../utils/commonFunctions';
import { arrayToString } from '../../../../utils/commonFunctions/convert';
import { FormatDate } from '../../../../utils/formatDate';
import { useSortByColumn } from '../../../../Hooks/useSortByColumn';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

const headCells = [
  // {
  //   id: 'id',
  //   center: false,
  //   disablePadding: true,
  //   sortArrow: true,
  //   label: 'system.users.table.id',
  // },
  // {
  //   id: 'username',
  //   center: false,
  //   disablePadding: true,
  //   sortArrow: true,
  //   label: 'system.users.user',
  // },
  {
    id: 'email',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.users.table.email',
    sticky: true,
    direction: 'left',
  },
  {
    id: 'name',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.users.table.fullname',
  },
  // {
  //   id: 'lastName',
  //   center: false,
  //   disablePadding: true,
  //   sortArrow: true,
  //   label: 'system.users.table.lastNames',
  // },
  {
    id: 'identification',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.users.table.identification',
  },
  {
    id: 'role',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.users.table.role',
    customSort: "role?.name",
  },
  {
    id: 'createAt',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.users.table.createAt',
    customSort: "createdAt",
  },
  {
    id: 'lastLogin',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.users.table.lastLogin',
    customSort: "lastLogin",
  },
  {
    id: 'company',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.users.table.companyUser',
    customSort: "company",
  },
  {
    id: 'contract',
    center: false,
    disablePadding: true,
    sortArrow: false,
    label: 'system.users.table.contract',
  },
  {
    id: 'plant',
    center: false,
    disablePadding: true,
    sortArrow: false,
    label: 'system.users.table.plants',
  },
  {
    id: 'authenticationType',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.users.table.authentication',
  },
  {
    id: 'isEnabled',
    center: false,
    disablePadding: true,
    sortArrow: true,
    label: 'system.users.table.status',
    customSort: "isEnabled ? 'Activo' : 'Inactivo'"
  },
  {
    id: 'actions',
    center: true,
    disablePadding: true,
    sortArrow: false,
    label: 'common.actions.title',
    sticky: true,
    direction: 'right',
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, pageRef, loadData } = props;
	const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);

  const createSortHandler = (property, customSort) => (event) => {
    let newOrder = 'asc'; // Valor inicial del orden (primer clic debería ser ascendente)

    if (orderBy === property && order === 'asc') {
      newOrder = 'desc'; // Si ya es ascendente, cambiar a descendente
    } else if (orderBy === property && order === 'desc') {
      newOrder = 'asc'; // Si ya es descendente, cambiar a ascendente
    }

    pageRef.current.order = property;
    pageRef.current.sort = newOrder.toUpperCase();
    onRequestSort(event, property, customSort);
    loadData();
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <CustomCell
            key={headCell.id}
            align={headCell.center ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            dataHead={headCell}
            // sx={{
            //   padding: '0 16px',
            //   color: colors.buttonActionColor,
            //   "&:hover": {
            //     color: colors.buttonActionColor,
            //     opacity: 0.9
            //   },
            // }}
          >
            {headCell.sortArrow == true &&
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id, headCell.customSort)}
              >
                {t(headCell.label) || headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span">
                  </Box>
                ) : null}
              </TableSortLabel>
            }
            {headCell.sortArrow == false &&
              <>
                <Box component="span">
                  {t(headCell.label) || headCell.label}
                </Box>
              </>
            }
          </CustomCell>
        ))}
      </TableRow>
    </TableHead >
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function TableUsers({
  filteredRequest,
  rows,
  selectedUser,
  addNewRole,
  handleRemove,
  loadingData,
	currentPage,
  rowsCount,
  loadData,
  onChangePage,
  pageRef,
}) {
  const { mobile } = MediaQuerys;
	const { t } = useTranslation();
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);
  const { order, orderBy, stableSort, getComparator, handleRequestSort } = useSortByColumn({ defaultSort: 'lastLogin' })
  const {format} = new FormatDate();

  React.useEffect(() => {
    setPage(currentPage);
  }, [filteredRequest]);

  const handleChangePage = async(_, newPage) => {
    await onChangePage(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async(event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    await onChangePage(0, event.target.value, true);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box sx={{ width: '100%', marginTop: '16px', [mobile]: { marginTop: '15px'} }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <CustomTableContainer>
          <Table stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              loadData={loadData}
              pageRef={pageRef}
            />
            {loadingData && 
                <TableRow style={{ height: 33 * emptyRows, margin: '0 auto'}}>
                    <TableCell colSpan={12}>
                        <LoadingProgress/>                                        
                    </TableCell>
                </TableRow>
            }
						{!loadingData && 
              <TableContent>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.sort(getComparator(order, orderBy)).slice() */}
                  {stableSort(filteredRequest, getComparator())
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                        >
                          {/* <TableCell component="th" scope="row" sx={{ border: "none" }}>
                            {row.id}
                          </TableCell> */}
                          <StickyColumn direction="left">{row.email}</StickyColumn>
                          <TableCell sx={{ border: "none" }}>{`${row.name ?? ""} ${row.lastName ?? ""}`}</TableCell>
                          {/* <TableCell sx={{ border: "none" }}>{row.lastName}</TableCell> */}
                          <TableCell sx={{ border: "none" }}>{row.identification}</TableCell>
                          <TableCell sx={{ border: "none" }}>{row.role?.name}</TableCell>
                          <TableCell sx={{ border: "none" }}>{format({date: row?.createdAt, format: true})}</TableCell>
                          <TableCell sx={{ border: "none" }}>{format({ date: row?.lastLogin, format: true })}</TableCell>
                          <TableCell sx={{ border: "none" }}>{row?.company?.name}</TableCell>
                          {row.contractPlants?.length > 0 ?
                              <>
                                  <TableCell sx={{ border: "none" }}>
                                    {row.contractPlants[1] !== undefined ? (
                                        <Tooltip title={returnStringContract(row.contractPlants, true)}>
                                          <ContainerGroup>
                                            <div>
                                              <CustomTyphography>{row.contractPlants[0]?.contract?.code || ''}</CustomTyphography>
                                            </div>
                                            <div>
                                              <IconArea>{`+${row.contractPlants.length - 1}`}</IconArea>
                                            </div>
                                          </ContainerGroup>
                                        </Tooltip>
                                      ) : (
                                        <span>{returnStringContract(row.contractPlants)}</span>
                                      )
                                    }
                                  </TableCell>
                                  <TableCell sx={{ border: "none" }}>
                                    {row.contractPlants[0]?.plants[1] !== undefined ? (
                                        <Tooltip title={returnStringPlants(row.contractPlants)[1]}>
                                          <ContainerGroup>
                                            <div>
                                              <CustomTyphography>{row.contractPlants[0]?.plants[0]?.name || ''}</CustomTyphography>
                                            </div>
                                            <div>
                                              <IconArea>{`+${returnStringPlants(row.contractPlants)[0] - 1}`}</IconArea>
                                            </div>
                                          </ContainerGroup>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title={returnStringPlants(row.contractPlants, null, true)[1]}>
                                          <ContainerGroup>
                                            <div>
                                              <CustomTyphography>{returnStringPlants(row.contractPlants, true)[1][0]}</CustomTyphography>
                                            </div>
                                            <div>
                                                {returnStringPlants(row.contractPlants)[0] > 1 && (
                                                  <IconArea>
                                                    {`+${returnStringPlants(row.contractPlants)[0] - 1}`}
                                                  </IconArea>
                                                 )  
                                                }
                                            </div>
                                          </ContainerGroup>
                                        </Tooltip>
                                      )
                                    }
                                  </TableCell>
                              </>
                          :
                              <>
                                  <TableCell sx={{ border: "none" }}>{row.contract?.code}</TableCell>
                                  <TableCell sx={{ border: "none" }}>
                                    {row.plants?.length > 0 ? (
                                      row.plants[1] !== undefined ? (
                                        <Tooltip title={arrayToString(row.plants, 'name')}>
                                          <ContainerGroup>
                                            <div>
                                              <CustomTyphography>{row.plants[0].name}</CustomTyphography>
                                            </div>
                                            <div>
                                              <IconArea>{`+${row.plants.length - 1}`}</IconArea>
                                            </div>
                                          </ContainerGroup>
                                        </Tooltip>
                                      ) : (
                                        <span>{row.plants[0].name}</span>
                                      )
                                    ) : (
                                      <span>{row.plant?.name || ''}</span>
                                    )}
                                  </TableCell>
                              </>
                          }
                          <TableCell sx={{ border: "none" }}>
                            {row.authenticationType === 'ACTIVE_DIRECTORY' ? t('system.users.table.actDir') : t('system.users.table.app')}
                          </TableCell>
                          <TableCell sx={{ border: "none" }}>
                            <Text enabled={row.isEnabled}>{row.isEnabled ? t('system.users.table.active') : t('system.users.table.inactive')}</Text>
                          </TableCell>
                          <StickyColumn direction="right">
                            {row.isEnabled && (
                              <GroupAction>
                                {ability.can("UpdateUser") &&
                                  <Tooltip title={t('system.users.editUser')}>
                                    <IconsButtonsTable
                                      needMargin={true}
                                      size="small"
                                      aria-label="detail"
                                      onClick={() => selectedUser(row, 'UPDATE')}
                                    >
                                      <EditIcon />
                                    </IconsButtonsTable>
                                  </Tooltip>
                                }
                                
                                {ability.can("UpdateUser") &&
                                  <Tooltip title={t('system.users.table.newRole')}>
                                    <IconsButtonsTable
                                      needMargin={true}
                                      size="small"
                                      aria-label="detail"
                                      onClick={() => addNewRole(row, 'ADD_ROLE')}
                                    >
                                      <ContentCopyIcon/>
                                    </IconsButtonsTable>
                                  </Tooltip>
                                }

                                {ability.can("ToggleStateUser") &&
                                  <Tooltip title={t('system.users.table.inactiveUser')}>
                                    <IconsButtonsTable
                                      needMargin={true}
                                      size="small"
                                      aria-label="detail"
                                      onClick={() => handleRemove(row, 'DEACTIVATE')}
                                    >
                                      <DeleteIcon />
                                    </IconsButtonsTable>
                                  </Tooltip>
                                }
                              </GroupAction>
                            )}
                            {!row.isEnabled && (
                              <>
                              {ability.can("ToggleStateUser") &&
                                <Tooltip title={t('system.users.table.activeUser')}>
                                  <IconsButtonsTable
                                      size="small"
                                      aria-label="detail"
                                      onClick={() => handleRemove(row, 'ACTIVATE')}
                                    >
                                      <CheckIcon />
                                    </IconsButtonsTable>
                                </Tooltip>
                              }
                              </>
                            )}
                          </StickyColumn>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
              </TableContent>
            }
          </Table>
        </CustomTableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton
          showLastButton={false}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('common.itemsPerPage')}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ` ${t('common.of')} ` + count
            }
          }
          sx={{
            borderTop: '1px solid ' + colors.gray,
          }}
        />
      </Paper>
    </Box>
  );
}
