import React, { useCallback } from 'react'

// Components
import { FormButton } from '../../../../pages/styles';
import CustomStepper from '../../../molecules/CustomStepper'
import { ShowDialog } from '../../../ShowDialog';
import { BackdropLoading } from '../../../BackdropLoading';
import { toast } from 'react-toastify';

// Styles
import { Container, Header, Body, Footer, ContainerInfo, SectionRight } from './styles'

// Utils
import { useTranslation } from 'react-i18next'
import { CheckResponse } from '../../../../utils/checkResponse';
import { Auth } from '../../../../utils/auth';
import { arrayMultipleData } from '../../../../utils/commonFunctions/returnArrayMultiple';
import { validateFiles } from '../../../../utils/commonFunctions';

// Service
import { PlantApi } from '../../../../services/PlantApi';
import { PlantCategoryApi } from '../../../../services/PlantCategoryApi';
import { RoleApi } from '../../../../services/RoleApi';
import { ContractApi } from '../../../../services/ContractApi';
import { CompanyApi } from '../../../../services/CompanyApi';
import { UserApi } from '../../../../services/UserApi';
import { SecurityApi } from '../../../../services/SecurityApi';
import { PasswordPolicyApi } from '../../../../services/PasswordPolicyApi';

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../../Context/PermissionsContext';

export const UserModalCard = ({
  title,
  addRole,
  selectedData,
  action,
  authTypeRef,
  emptyRow,
  handleCloseModalUser,
  loadData
}) => {
  const { t } = useTranslation();
  const [steps, setSteps] = React.useState([]);

  const plantApi = new PlantApi();
  const plantCategoryApi = new PlantCategoryApi();
  const roleApi = new RoleApi();
  const contractApi = new ContractApi();
  const companyApi = new CompanyApi();
  const userApi = new UserApi();
  const securityApi = new SecurityApi();
  const passwordPolicyApi = new PasswordPolicyApi();
  const {check} = new CheckResponse();
  const { getUser } = new Auth();

  const ability = useAbility(AbilityContext);

  const plants = React.useRef([]);
  const roles = React.useRef([]);
  const contracts = React.useRef([]);
  const companies = React.useRef([]);
  const user = React.useRef({});
  const buttonRef = React.useRef();
  const isCheckSupervisor = React.useRef(false);

  const [roleList, setRoleList] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState({ id: 0, name: "" });
  const [selectedCompany, setSelectedCompany] = React.useState({ id: 0, name: "" });
  const [roleType, setRoleType] = React.useState("");

  const [plantList, setPlantList] = React.useState([]);
  const [selectedPlants, setSelectedPlants] = React.useState([]);

  const [contractList, setContractList] = React.useState([]);
  const [companyList, setCompanyList] = React.useState([]);
  const [passwordPolicyData, setPasswordPolicyData] = React.useState({
    id: 0,
    isActive: false,
    passwordSecurity: false,
    passwordComments: ''
  });
  const [validateEmail, setValidateEmail] = React.useState(true);

  const [files, setFiles] = React.useState([])
  const [plantCategories, setPlantCategories] = React.useState([])
  const [companyContracts, setCompanyContracts] = React.useState([])
  const [loading, setLoading] = React.useState(false);

  const [showPasswordGenerator, setShowPasswordGenerator] = React.useState(false);

  const [form, setForm] = React.useState({
    id: 0,
    username: "",
    name: "",
    lastName: "",
    identification: "",
    email: "",
    role: "",
    isEnabled: true,
    password: "",
    confirm_password: "",
    authenticationType: true,
    plants: [],
    plantsId: [],
    contract: '',
    contractId: null,
    contractPlantsAssigned: [],
    contractPlants: [],
    contractPlantsUser: [],
    isContractor: false,
    company: {}
  });

  const [error, setError] = React.useState({
    name: { hasError: false, message: "" },
    lastName: { hasError: false, message: "" },
    identification: { hasError: false, message: "" },
    lastLogin: { hasError: false, message: "" },
    email: { hasError: false, message: "" },
    username: { hasError: false, message: "" },
    role: { hasError: false, message: "" },
    password: { hasError: false, message: "" },
    general: { hasError: false, message: "" },
    plants: { hasError: false, message: "" },
    contract: { hasError: false, message: "" },
    contractId: { hasError: false, message: "" },
    company: { hasError: false, message: "" },
    contractPlantsAssigned: [],
  });

  const [dialog, setDialog] = React.useState({
    title: '',
    message: '',
    status: false
  });

  const loadRoles = useCallback(async () => {
    const user = getUser();
    let response = await roleApi.list(user?.role?.id || null);
    if (response.status === 200) {
      let resp = response.data.data;
      setRoleList(resp);
      roles.current = resp;
    }
  }, [roleApi]);

  const loadPlants = useCallback(async () => {
    if (ability.can("PlantList")) {
        let response = await plantApi.list();
        if (response.status === 200) {
          let resp = response.data;
          setPlantList(resp);
          plants.current = resp
        }
    }
  }, [plantApi]);

  const loadContracts = useCallback(async () => {
    if (ability.can("ContractList")) {
      let response = await contractApi.list();
      if (response.status === 200) {
        let activeContracts = response.data.filter((obj) => obj.isActive)
        setContractList(activeContracts);
        contracts.current = activeContracts
      }
    }
  }, [contractApi]);

  const loadCompanies = useCallback(async () => {
    if (ability.can("AllCompanies")) {
      let response = await companyApi.list(true);
      if (response.status === 200) {
        let data = response.data?.map((item) => {
          return {
            ...item,
            provider: `${item.nit ?? ""}-${item?.dv ?? ""}, ${item.name ?? ""}`
          }
        })
        setCompanyList(data);
        companies.current = data
      }
    }
  }, [companyApi]);

  const loadPasswordPolicy = useCallback(async() => {
    let response = await passwordPolicyApi.get()
    if (response?.status === 200) {
        let resp = response.data
        setPasswordPolicyData({
            ...resp,
            passwordRule: resp.passwordRule || '',
        })
    }
  }, [passwordPolicyApi])

  const checkContract = async(contract) => {
    setLoading(true)
    let response = await contractApi.getAttachmentsContractSupervisor(contract.id);
    setLoading(false)
    if (response.status === 200) return true
    else {
      toast.error(t('system.validations.errorSupports', {contract: contract.code}));
      return false
    }
  };

  const initCreateForm = () => {
    setForm({
      ...form,
      id: 0,
      username: "",
      name: "",
      lastName: "",
      identification: "",
      lastLogin: "",
      email: "",
      role: "",
      isEnabled: true,
      password: "",
      confirm_password: "",
      authenticationType: false,
      plants: [],
      plantsId: [],
      contract: "",
      contractId: null,
      contractPlantsUser: [],
      contractPlantsAssigned: emptyRow(),
      contractPlants: [],
      isContractor: false
    });
    setSelectedPlants([]);
    setSelectedRole({ id: 0, name: "" });
    setSelectedCompany({ id: 0, name: "" });
  }

  const initUpdateForm = () => {
    let role = roles.current?.find((el) => el.id === selectedData?.role?.id);
    role = role ?? selectedData?.role
    selectedData.role = role

    if (!role?.isExternal && !role?.isRestricted) {
      let selPlants = arrayMultipleData(selectedData.plants, plants.current)
      setSelectedPlants(selPlants);
      selectedData.selectedPlants = selPlants;
      selectedData.plants = selPlants
      setRoleType("internalWithoutContract")
    }
    if (!role?.isExternal && role?.isRestricted) {
      setRoleType("internalWithContract")
    }
    if (role?.isExternal && role?.isRestricted) {
      setRoleType("externalWithContract")
      if (selectedData.company !== null) {
        let companyData = {
          ...selectedData.company,
          provider: `${selectedData.company?.nit ?? ""}-${selectedData.company?.dv ?? ""}, ${selectedData.company?.name ?? ""}`
        }
        setSelectedCompany(companyData);
        getContractsByCompany(selectedData.company)
      } else setSelectedCompany(selectedData.company);
    }
    setSelectedRole(role);
    setForm({...form, ...selectedData})
    showCheckSupervisor(role)
  }

	const configSteps = () => {
    let userSteps = [t('system.users.stepper.user'), t('system.users.stepper.scope')];
    if (ability.can("UserAttachmentCreate")) {
      userSteps.push(t('system.users.stepper.attachments'))
    }
    
    setSteps(userSteps)
	}

  React.useEffect(() => {
    async function call() {
      setLoading(true)
      await getPlantCategories();
      await loadRoles();
      await loadPlants();
      await loadContracts();
      await loadCompanies();
      await loadPasswordPolicy()
      user.current = getUser();

      if(form.email.length <= 0 && action === 'CREATE') {
        setValidateEmail(true);
        initCreateForm();
      }
      if(action === 'UPDATE' || action === 'ADD_ROLE') {
        setValidateEmail(false);
        initUpdateForm();
      }
      setLoading(false)
    }
    call()
  }, [])

  React.useEffect(() => {
    configSteps()
  }, [form])

  const handleAuthentication = () => {
    authTypeRef.current = !authTypeRef.current;
    setForm({
      ...form,
      name: "",
      lastName: "",
      password: "",
      confirm_password: "",
      authenticationType: authTypeRef.current,
    });
  };
  
  const getData = () => {
    let data = { ...form };
    data.username = data.email // Se asigna el valor del email al campo username para registro en el Identity
    data.isEnabled = true;
    data.authenticationType = authTypeRef.current
      ? "ACTIVE_DIRECTORY"
      : "LOCAL_AUTHENTICATION";
    data.password = authTypeRef.current ? null : form.password;
    data.name = authTypeRef.current ? "" : form.name
    data.lastName = authTypeRef.current ? "" : form.lastName
    data.role = selectedRole;

    if (roleType === 'internalWithoutContract') {
      data.company = null;
      data.contractPlantsAssigned = [];
      data.plantsId = data.plants?.map((el) => el.id);
    }
    else if (roleType === 'internalWithContract') {
      data.company = null;
    }
    if (roleType === 'externalWithContract' || roleType === 'internalWithContract') {
      data.plants = [];
      let array = []
      data.contractPlantsAssigned?.map((el) => {
        el.selectedPlants?.map((el2) => {
          array.push({contractId: el.contract?.id, plantId: el2.id, userType: el.userType ?? 2})
        })
      })
      data.contractPlants = array;
    }

    return data;
  };

  const resetError = () => {
    setError({
      name: { hasError: false, message: "" },
      lastName: { hasError: false, message: "" },
      identification: { hasError: false, message: "" },
      lastLogin: { hasError: false, message: "" },
      email: { hasError: false, message: "" },
      username: { hasError: false, message: "" },
      role: { hasError: false, message: "" },
      password: { hasError: false, message: "" },
      general: { hasError: false, message: "" },
      plants: { hasError: false, message: "" },
      contract: { hasError: false, message: "" },
      contractId: { hasError: false, message: "" },
      company: { hasError: false, message: "" },
      contractPlantsAssigned: [],
    });
  };

  const resetForm = () => {
    loadContracts()
    loadCompanies()
    setForm({
      ...form,
      id: 0,
      username: "",
      name: "",
      lastName: "",
      identification: "",
      email: "",
      role: "",
      isEnabled: true,
      password: "",
      confirm_password: "",
      authenticationType: true,
      plants: [],
      plantsId: [],
      contract: '',
      contractId: null,
      contractPlantsAssigned: [],
      contractPlants: [],
      contractPlantsUser: [],
      isContractor: false,
      company: {}
    });
    setSelectedPlants([])
    setContractList(contracts.current)
    setPlantList(plants?.current || [])
    setRoleType("")
    setSelectedRole("")
    setSelectedCompany({})
    resetError();
    selectedData = {}
  };

  const handleFilesDrop = (droppedFiles) => {
    setFiles(droppedFiles)
  }

  async function uploadFiles(id) {
    if (ability.can("UserAttachmentCreate")) {
      if (files.length > 0) {
        setLoading(true)

        let formData = new FormData()
        formData.append('userId', id)
        files?.map(file => { formData.append("files", file) })

        const response = await userApi.upload(formData, false);
        let result = check(response)

        if (result.status) {
          setDialog({
            status: true, isClose: true,
            title: t('files.messages.saveFile.title'),
            message: t('files.messages.saveFile.message')
          })
        }
        else {
          toast.error(() =>
            <div>
              {result.errors}
            </div>
          );
        }
        setLoading(false)
      }
    }
  }

  const addRoleUser = async () => {
    resetError();
    let isValid = false;
    const data = getData();
    setDialog(true);
  
    try {
      setLoading(true);
      const resp = await securityApi.signup(data);
  
      if (resp?.data.code === 201) {
        isValid = true;
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  
    return isValid;
  };
  
 
  const handleSave = async() => {
    if (action === "CREATE") {
      return await createUser();
    }else if(action === 'ADD_ROLE'){
      return await addRoleUser();
    }
    else {
      return await updateUser();
    }
  };

  const createUser = async() => {
    resetError();
    
    let isValid = false
    const data = getData();
    setLoading(true);
    await securityApi.signup(data).then(async (resp) => {
      setLoading(false);
      if (resp?.status === 200) {
        selectedData.id = resp.data.data.id;
        isValid = true
      } else {
        toast.error(() =>
          <div>{t('system.users.messages.saveData.error')}<br />
            <span>{resp?.data?.description || resp?.data?.data?.description || resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving')}</span>
          </div>
        );
      }
    });

    return isValid
  };

  const updateUser = async() => {
    resetError();

    let isValid = false
    const data = getData();
    data.password = form.password === "" ? null : form.password;
    setLoading(true);
    await userApi.update(data.id, data).then(async (resp) => {
      setLoading(false);
      if (resp?.status === 204) {
        isValid = true
      } else {
        toast.error(() =>
          <div>{t('system.users.messages.updatedData.error')}<br />
            {resp?.data?.description || resp?.data?.data?.description || resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving')}
          </div>
        );
      }
    });

    return isValid
  };

  async function getPlantCategories() {
    const response = await plantCategoryApi.list();
    const result = check(response);

    if (result.status) {
      setPlantCategories(response.data)
      return response.data
    }
    else {
      toast.error(() => <>{result.errors}</>)
    }
    return []
  }

  const handleSetRole = (value) => {
    if (!value.isExternal && !value.isRestricted) setRoleType("internalWithoutContract")
    if (!value.isExternal && value.isRestricted) setRoleType("internalWithContract")
    if (value.isExternal && value.isRestricted) setRoleType("externalWithContract")
    
    setSelectedRole(value);
    setSelectedCompany({});
    setForm({...form, role: value, company: {}, contractPlantsAssigned: [], contractPlants: [], plants: []});
    setError({...error, role: { hasError: false, message: ""}})
    showCheckSupervisor(value);
  };

  const handleSetCompany = async(value) => {
    setSelectedCompany(value);
    setForm({ ...form, company: value, contractPlantsAssigned: [] });
    getContractsByCompany(value)
    setError({...error, company: { hasError: false, message: "" }})
  };

  const getContractsByCompany = (value) => {
    let array = [];

    contracts.current?.map((el) => {
      if (el.company?.id === value.id) {
        if (isSupervisor()) {
          if (el.technicalSupervisors?.some((tech) => tech.email === user.current.email)) {
            array.push(el)
          }
        }
        else {
          array.push(el)
        }
      }
    })
    setCompanyContracts(array)
  }

  const handleSetPlants = (value) => {
    setForm({...form, plants: value})
    setError({...error, plants: { hasError: false, message: ""}});
  };

  const handleChangeStepOne = (event, name) => {
    const value = name === 'email' ? event.target.value.toLowerCase() : event.target.value;
    if (name === 'email') setValidateEmail(true)
    setForm({...form, [name]: value });
    setError({...error, [name]: { hasError: false, message: ""}})
  }

  const handleChangeStepTwo = async(value, name, index) => {
    let array = [...form.contractPlantsAssigned]
    let errors = array

    if (name === 'contract') {
      const check = await checkAvailableContract(value);

      if (check) {
        array[index].plants = value.plants;
        array[index].selectedPlants = [];
        array[index].contract = value;
  
        errors[index].errorsEmptyContract = "";
        errors[index].hasEmptyContract = false;
      }
    } else if (name === 'plants') {
      array[index].selectedPlants = value;
      
      errors[index].errorEmptyPlants = "";
      errors[index].hasEmptyPlants = false;
    } else {
      array[index].userType = value
    }

    setForm({...form, contractPlantsAssigned: array})
    setError({...error, contractPlantsAssigned: errors})
    return array
  }

  const handleGenerateClick = () => {
    setShowPasswordGenerator(showPasswordGenerator => !showPasswordGenerator);
  };

  const handleGeneratePassword = (password) => {
    const newPassword = password || ''
    setForm({ ...form, password: newPassword, confirm_password: newPassword })
    handleGenerateClick()
  };

  const nextValidation = async(step) => {
    let validation = false;
    if (selectedData.id && action === 'CREATE') {
      action = 'UPDATE';
      setForm({...form, ...selectedData})
    }

    if (step === 0) {
      if(action === 'ADD_ROLE'){
        const resp = await securityApi.userRoles(form?.email);
        if (resp.status === 200) {
          let matchRole = resp.data?.some(el => el.id === selectedRole?.id)

          if(matchRole){
            setError({...error, role: { hasError: true, message: t('system.users.messages.role.error') }})
            toast.error(t("system.users.messages.role.error"));
            return
          }else {
            setError({...error, role: { hasError: false, message: "" } })
            // toast.success(t("system.users.messages.role.message"));
          }
        }
      }
      validation = stepOneCheck()
    }
    else if (step === 1) {
      let stepValidation = false
      stepValidation = await stepTwoCheck()
      if (!ability.can("UserAttachmentCreate") && stepValidation) {
        const isSaveUser = await handleSave();

        if (isSaveUser) {
          loadData();
          setDialog({
            title: t('system.users.messages.saveData.title'),
            message: t('system.users.messages.saveData.message'),
            status: true,
            closeModalUser: true
          })
        }
      }
      else validation = stepValidation
    }
    else if (step === 2) {
      validation = await stepThreeCheck()
    }

    return validation
  };

  async function validatePasswordSecurity(username, password) {
    let data = { username, password }
    let valid = true;
    setLoading(true)
    await passwordPolicyApi.validate(data).then((resp) => {
        setLoading(false)
        if (resp?.status !== 200) {
          toast.error(resp?.data?.description || resp?.data?.data?.description || resp?.response?.data?.description || resp?.response?.data?.data?.description);
          valid = false;
        }
      })
    return valid
  }

  const stepOneCheck = async() => {
    let errors = {};
    const generalError = { hasError: true, message: t("system.validations.mandatory") };

    if (!form.email) errors.email = generalError;
    else {
      if (validateEmail) errors.email = { hasError: true, message: t("system.users.messages.validatedEmail.titleError") };
      if (!form.identification) errors.identification = generalError;
      if (!form.role) errors.role = generalError;
      if (!authTypeRef.current) {
        if (!form.name) errors.name = generalError;
        if (!form.lastName) errors.lastName = generalError;
        if (action === 'CREATE' && !form.password) errors.password = generalError;
        if (action === 'CREATE' && !form.confirm_password) errors.confirm_password = generalError;
        if (action === 'CREATE' && (form.password !== form.confirm_password)) errors.confirm_password = { hasError: true, message: t("system.users.messages.password.titleError") };

        if (passwordPolicyData.isActive && form.email !== "" && form.password !== "") {
          let result = await validatePasswordSecurity(form.email, form.password)
          if (!result) {
            errors.password = { hasError: true, message: t("system.users.messages.password.securityError") };
            errors.confirm_password = { hasError: true, message: t("system.users.messages.password.securityError") };
          }
        }
      }
    }

    // console.log("errors ==> ", errors)
    if (Object.keys(errors)?.length > 0) {
      setError({...error, ...errors});
      toast.error(t("system.validations.errorsForm"));
      return false
    }

    setError({...error});
    return true
  }

  const stepTwoCheck = async() => {
    let errors = {};
    const generalError = { hasError: true, message: t("system.validations.mandatory") };

    if (roleType === 'internalWithoutContract') {
      if (!form.plants?.length) errors.plants = generalError;
    }
    else if (roleType === 'internalWithContract') {
      let validate = validateContractScopes();
      if (validate.hasError) errors.contractPlantsAssigned = validate.contractPlantsAssigned;
    }
    else if (roleType === 'externalWithContract') {
      if (!form.company?.id) errors.company = generalError;
      let validate = validateContractScopes();
      if (validate.hasError) errors.contractPlantsAssigned = validate.contractPlantsAssigned;
    }

    if (Object.keys(errors)?.length > 0) {
      setError({...error, ...errors});
      toast.error(t("system.validations.errorsForm"));
      return false
    }

    setError({...error});
    return true
  }

  const checkAvailableContract = async(contract) => {
    if (isSupervisor()) {
      return await checkContract(contract)
    }
    return true
  }

  const stepThreeCheck = async() => {
    const isSaveUser = await handleSave();
    if (isSaveUser) {
      let isValid = false
  
      if (files.length > 0) {
        setLoading(true)
        let resultCheck = await validateFiles(files)
        setLoading(false)
        
        if (resultCheck) {
          await uploadFiles(selectedData.id);
          setFiles([])
          isValid = true
        }
      }
      else isValid = true
  
      if (isValid) {
        loadData();
        setDialog({
          title: t('system.users.messages.saveData.title'),
          message: t('system.users.messages.saveData.message'),
          status: true,
          closeModalUser: true
        })
      }
    }
    else return false
  }

  function checkItems(array) {
    return array?.map(item => ({
      ...item,
      hasEmptyPlants: !item.selectedPlants?.length,
      errorEmptyPlants: !item.selectedPlants?.length ? t("system.validations.mandatory") : '',
      hasEmptyContract: !item.contract?.id,
      errorEmptyContract: !item.contract?.id ? t("system.validations.mandatory") : ''
    }))
  }

  const validateContractScopes = () => {
    let hasError = false;

    let array = form.contractPlantsAssigned
    if (array?.length > 0) {
      array = checkItems(array)

      const hasEmptyPlants = array.some(item => item.hasEmptyPlants);
      const hasEmptyContract = array.some(item => item.hasEmptyContract);

      if (hasEmptyPlants || hasEmptyContract) hasError = true;
    }
    else {
      hasError = true;
    }

    return {hasError: hasError, contractPlantsAssigned: array};
  }

  const handleValidateEmail = async () => {
    if (ability.can("GetUser")) {
      const { email } = form;
      let errors = { ...error };
      if (!checkEmailFormat(email, errors)) return

      let response = await userApi.detail(email, form.authenticationType);
      const status = response.status || response.response?.status;

      let userId = response?.data?.id || response?.data?.data?.id
      if (form.authenticationType) {
        // Si el status es 200 y el id existe, el usuario ya fue creado (retorna error)
        if (status === 200 && userId) {
          errorResponse()
        }
        // Si el status es 404 el usuario no existe en el DA (retorna error)
        if (status === 404) {
          errorResponse(true)
        }
        // Si el status es 200 y el id es (0) o null, el usuario sí existe en el DA
        // pero no existe en la app (retorna ok)
        if (status === 200 && !userId) {
          successfulResponse(errors)
  
          if (response?.data?.data?.name) {
            let userData = response.data.data;
            setForm({...form, name: userData.name, lastName: userData.lastName, disableAD: true})
          }
        }
      }
      else {
        // Si el status es 200 el usuario ya fue registrado (retorna error)
        if (status === 200) {
          errorResponse()
        }
        // Si el status es 404 el usuario no está registrado (retorna ok)
        if (status === 404) {
          successfulResponse(errors)
        }
      }
    }

    return setDialog;
  }

  const showCheckSupervisor = (value) => {
    if (action === 'ADD_ROLE' && !value) return;
    isCheckSupervisor.current = (value.name === 'Lider integral' || value.name === 'Líder integral')
  }

  const isSupervisor = (roleValidate = 'externalWithContract') => {
    return (user.current?.role?.name === 'Supervisor técnico' && roleType === roleValidate)
  }

  const checkEmailFormat = (email, errors) => {
    const emailRequired = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    
    if (!emailRequired) {
      errors.email = { hasError: true, message: t("system.validations.email") };
      setError(errors);
      setValidateEmail(true);
      setDialog({
        status: true,
        title: t('system.users.messages.validatedEmail.titleError'),
        message: t('system.validations.email'),
        typeResponse: 'error',
      })
      return false
    }
    return true
  }

  const errorResponse = (isAD) => {
    let message = isAD ? 'messageErrorAD' : 'messageError'
    setValidateEmail(true);
    setDialog({
      status: true,
      title: t('system.users.messages.validatedEmail.titleError'),
      message: t('system.users.messages.validatedEmail.' + message),
      typeResponse: 'error',
    })
  }

  const successfulResponse = (errors) => {
    errors.email = { hasError: false, message: "" };
    setError(errors);
    setValidateEmail(false);
    setDialog({
      status: true,
      title: t('system.users.messages.validatedEmail.title'),
      message: t('system.users.messages.validatedEmail.message'),
      typeResponse: '',
    })
  }

  const handleOpenDialog = async () => {
    setLoading(true);
    await handleValidateEmail();
    setLoading(false);
  }

  const handleClose = () => {
    resetForm();
    setValidateEmail(false);
    handleCloseModalUser();
  };

  function handleCloseDialog() {
    if (dialog.closeModalUser) handleClose()
    setDialog({ ...dialog, status: false })
  }

  return (
    <Container>
      <Header>{title}</Header>
      <Body>
        <ContainerInfo>
          <CustomStepper
            steps={steps}
            addRole={addRole}
            nextValidation={nextValidation}
            form={form}
            setForm={setForm}
            data={selectedData}
            roleList={roleList}
            selectedRole={selectedRole}
            selectedPlants={selectedPlants}
            errors={error}
            setErrors={setError}
            loading={loading}
            authTypeRef={authTypeRef.current}
            handleChangeStepOne={handleChangeStepOne}
            handleChangeStepTwo={handleChangeStepTwo}
            handleSetRole={handleSetRole}
            handleAuthentication={handleAuthentication}
            handleValidateEmail={handleOpenDialog}
            validateEmail={validateEmail}
            buttonRef={buttonRef}
            handleGeneratePassword={handleGeneratePassword}
            showPasswordGenerator={showPasswordGenerator}
            setShowPasswordGenerator={setShowPasswordGenerator}
            handleGenerateClick={handleGenerateClick}
            roleType={roleType}
            categories={plantCategories}
            plants={plants.current}
            companies={companyList}
            handleFilesDrop={handleFilesDrop}
            handleSetCompany={handleSetCompany}
            handleSetPlants={handleSetPlants}
            selectedCompany={selectedCompany}
            companyContracts={companyContracts}
            user={user.current}
            isSupervisor={isSupervisor}
            passwordPolicyData={passwordPolicyData}
            showCheckSupervisor={isCheckSupervisor.current}
          />
        </ContainerInfo>
      </Body>
			<Footer>
      	<SectionRight>
        	<FormButton variant="outlined" type='close' onClick={handleClose} disabled={loading}>
            {t('common.cancel')}
          </FormButton>
        </SectionRight>
      </Footer>

      <BackdropLoading open={loading} />
      <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog} />
    </Container>
  )
}
