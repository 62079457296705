import React from 'react';
import { Container, ContainerTopGrahps, ContainerBottomGrahps, ContentGraphics, CustomTitleGraphics } from './style';
import { CardGraphic } from '../CardGraphic';
import { ComponentNoDataCharts } from '../NoDataGraphic';
import { MenuItem, Select, FormControl } from '@mui/material';

const colors = [
    '#10476b', '#0778a6', '#b6b310', '#145f89', '#105b73', '#0b4b7a', '#0d5b8c',
    '#125f83', '#095f76', '#066d8a', '#1c6b96', '#1173a0', '#138fb4', '#11a0a0',
    '#0c8a9d', '#0d7b88', '#92a611', '#a9b211', '#838e13', '#6b7b14'
];

const optionsDonut = {
    chart: { type: 'donut' },
    colors: colors,
    legend: { show: false },
    dataLabels: {
        enabled: true,
        formatter: function (val, { seriesIndex, w }) {
            return w.config.labels[seriesIndex];
        }
    }
};

const optionsBar = {
    chart: {
        type: 'bar',
        stacked: true,
        toolbar: {
            show: false
        }
    },
    colors: colors,
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
        }
    },
    dataLabels: { enabled: false },
    xaxis: { categories: ['Frío', 'Caliente'] },
    legend: { show: false }
};

export const GraphicNewGeneralReport = ({ filters, chartRef, graphicDonutUp, graphicCritical, graphicHistorical, graphicColdHot, handleChangeHistorical }) => {

    const chartKeys = {
        'Inscripción': 'inscription',
        'Planeación': 'programming',
        'Ejecución': 'execution',
        'Suspensión': 'suspension',
        'Cierre': 'closure'
    };

    const months = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const years = [
        new Date().getFullYear() - 2,
        new Date().getFullYear() - 1,
        new Date().getFullYear()
    ];

    return (
        <Container>
            <ContainerTopGrahps>
                {Object.entries(chartKeys).map(([title, key]) => {
                    const chartData = graphicDonutUp[key] || { labels: [], series: [] };

                    return (
                        <ContentGraphics key={key} width={'20%'}>
                            <CustomTitleGraphics>{title}</CustomTitleGraphics>
                            {chartData.series?.some(el => el > 0) ? (
                                <CardGraphic
                                    type="donut"
                                    options={{
                                        ...optionsDonut,
                                        labels: chartData.labels
                                    }}
                                    series={chartData.series}
                                    chartRef={chartRef}
                                />
                            ) : (
                                <ComponentNoDataCharts isFlow={false} height={'100%'} />
                            )}
                        </ContentGraphics>
                    );
                })}
            </ContainerTopGrahps>


            <ContainerBottomGrahps>
                <ContentGraphics width="33%">
                    <CustomTitleGraphics>Viabilizaciones asociadas a actividades criticas</CustomTitleGraphics>
                    {graphicCritical.series?.some(el => el > 0) ? (
                        <CardGraphic
                            type="donut"
                            options={{
                                ...optionsDonut,
                                labels: graphicCritical.labels ?? [],
                            }}
                            series={graphicCritical.series ?? []}
                            chartRef={chartRef}
                        />
                    ) : (
                        <ComponentNoDataCharts isFlow={false} height={'100%'} />
                    )}
                </ContentGraphics>

                <ContentGraphics width="33%">
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                        <CustomTitleGraphics>Histórico</CustomTitleGraphics>

                        <FormControl size="small">
                            <Select
                                value={filters.year}
                                onChange={(e) => handleChangeHistorical(e.target.value, 'year')}
                                displayEmpty
                            >
                                <MenuItem value="" disabled>
                                    Año
                                </MenuItem>
                                {years.map((year, index) => (
                                    <MenuItem key={index} value={year}>{year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl size="small">
                            <Select
                                value={filters.month}
                                onChange={(e) => handleChangeHistorical(e.target.value, 'month')}
                                displayEmpty
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                            overflowY: "auto"
                                        }
                                    }
                                }}
                            >
                                <MenuItem value=''>
                                    -
                                </MenuItem>
                                {months.map((month, index) => (
                                    <MenuItem key={`${month} - ${index + 1}`} value={index + 1}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    {graphicHistorical.series?.some(el => el > 0) ? (
                        <CardGraphic
                            type="donut"
                            options={{
                                ...optionsDonut,
                                labels: graphicHistorical.labels ?? [],
                            }}
                            series={graphicHistorical.series ?? []}
                            chartRef={chartRef}
                        />
                    ) : (
                        <ComponentNoDataCharts isFlow={false} height={'100%'} />
                    )}
                </ContentGraphics>

                <ContentGraphics width="33%">
                    <CustomTitleGraphics>Frío/Caliente</CustomTitleGraphics>
                    {graphicColdHot.series?.some(serie => serie.data.some(value => value > 0)) ? (
                        <CardGraphic
                            type="bar"
                            options={{
                                ...optionsBar,
                                xaxis: { categories: graphicColdHot.categories ?? [] }
                            }}
                            series={graphicColdHot.series ?? []}
                            chartRef={chartRef}
                        />
                    ) : (
                        <ComponentNoDataCharts isFlow={false} height={'100%'} />
                    )}
                </ContentGraphics>
            </ContainerBottomGrahps>
        </Container>
    );
};
