

import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material';
import {
    Container,
    Form,
    Title,
    Subtitle,
    SectionRow,
    SectionInput,
    ContainerInput,
    Input,
    Span,
    SpanLink,
    SectionBtn,
    LabelCustom
} from './styles'

export const UserNameSection = ({
    handleChange,
    isLoading,
    colors,
    messageError,
    nextStep,
}) => {

    const { t } = useTranslation();

    return (
        <>
            <SectionRow>
                <SectionInput>
                    <LabelCustom>{t('login.user')}:</LabelCustom>
                    <ContainerInput>
                        <Input
                            name='username'
                            onChange={(e) => handleChange(e, 'username')}
                            required
                        />
                    </ContainerInput>
                </SectionInput>
            </SectionRow>

            <SectionRow>
                <SectionInput>
                    <Span>{messageError}</Span>
                </SectionInput>
            </SectionRow>

            <SectionBtn>
                <Button
                    variant="contained"
                    sx={{
                        color: colors.white,
                        backgroundColor: colors.buttonActionColor,
                        cursor: 'pointer',
                        padding: '.5em 2em',
                        borderRadius: '5px',
                        display: 'inline-block',
                        justifyContent: 'center',
                        transition: 'all .3s ease-in-out',
                        width: '50%',
                        "&.MuiButton-outlined.Mui-disabled": {
                            background: colors.grayGraphic,
                            cursor: 'not-allowed',
                        },
                        "&:hover": {
                            backgroundColor: colors.buttonActionColor,
                            opacity: 0.8,
                            color: colors.white,
                            transition: 'all .3s ease',
                        },
                        '&:active': {
                            transition: 'all .3s ease',
                            opacity: '1',
                        },
                    }}
                    onClick={() => nextStep()}
                    disabled={isLoading}
                >
                    {t('login.stepper.next')}
                </Button>
            </SectionBtn>
        </>
    )
}